import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/diarree-op-reis/"> Diarree op reis </a>
              </li>
              <li>
                <a href="/diarree-op-reis/reizigersdiaree-vermijden/">
                  Reizigersdiarree vermijden
                </a>
              </li>
              <li className="active">
                <a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">
                  Reizigersdiarree behandelen
                </a>
              </li>
              <li className="last">
                <a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>IMODIUM® Instant Smelttabletten</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/Imodium-Smelttablet-2mg-3D-Frontal-NL.png"
                    width="170"
                    height="102"
                    alt="IMODIUM® Instant Smelttabletten"
                  />
                </div>
                <p>
                  Handige hulp bij diarree. Inname zonder water en zonder
                  slikken. Smelt in enkele&nbsp;seconden op de tong.
                </p>
                <a href="/imodium-behandelen-diarree/imodium-instant/">
                  Meer informatie
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-35">
            <h1>Reizigersdiarree behandelen</h1>
            <div className="img">
              <img
                src="/assets/files/pages/reisen.jpeg"
                width="701"
                height="289"
                alt="Reizigersdiarree behandelen"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Hoe kan je reizigers-diarree behandelen?</h2>
              </div>
              <div className="nine columns">
                <p>Heb je toch pech en wordt je reis verpest door diarree?</p>
                <p>
                  Volg dan volgende richtlijnen om{" "}
                  <strong>reizigersdiarree</strong> te behandelen:
                </p>
              </div>
            </div>
            <ul>
              <li>
                Vul het verloren vocht aan door veel gekookt water of
                flessenwater te drinken.
              </li>
              <li>
                Neem een middel tegen <strong>diarree</strong> zoals
                <a href="/imodium-behandelen-diarree/imodium-instant/">
                  {" "}
                  IMODIUM
                </a>
                <a href="/imodium-behandelen-diarree/imodium-instant/">
                  <sup>®</sup> Instant&nbsp;smelttablet
                </a>
                , dat zonder extra water op de tong smelt, om snel de symptomen
                van <strong>diarree</strong> te behandelen.&nbsp;Lees aandachtig
                de bijsluiter voor gebruik. Je vindt hierop ook de juiste
                dosering bij volwassenen en kinderen boven de 8 jaar.&nbsp;
              </li>
              <li>
                Ga naar een arts, als de symptomen langer aanhouden dan 48 uur,
                als je meer dan 38 °C koorts krijgt of als je bloed of slijm in
                de ontlasting ziet.
              </li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
